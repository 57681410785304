import React, { Component } from "react";
import { Nav, NavItem } from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { AppSidebarToggler } from "@coreui/react";
import DefaultHeaderDropdown from "./DefaultHeaderDropdown";

import util from "../../util/util";
import session from "../../util/session";
import profiles from "../../util/profiles";
import { HeaderLogo } from "../../components/Logo/HeaderLogo";
// import util from '../../util/util';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  constructor(props) {
    super(props);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      width: 0,
      height: 0,
    };
  }

  /**
   * Retorna os links de menu, com base no perfil de acesso do usuário logado
   */
  getMenu() {
    const profile = session.getUserProfile();
    const userConfig = session.getUserConfigs();
    const worker =
      profiles.getProfileType(profile) !== profiles.PERMISSION_VIEW_ACTIVES;
    const actives =
      profiles.getProfileType(profile) !== profiles.PERMISSION_VIEW_WORKERS;
    const showSubContracted =
      profiles.isProfileContracted(profile) &&
      userConfig.contracted &&
      userConfig.contracted.type !== util.TYPE_CONTRACTED_SUBSUBCONTRACTED;
    // const users = profiles.isPageValid(profiles.USERS) !== '/login';
    return (
      <>
        {worker && (
          <NavItem className="px-3">
            <Link style={{ color: "#fff" }} to="/Workers/report">
              {util.translate("professionals")}
            </Link>
          </NavItem>
        )}
        {actives && (
          <NavItem className="px-3">
            <Link style={{ color: "#fff" }} to="/Actives/report">
              {util.translate("active")}
            </Link>
          </NavItem>
        )}
        {profiles.isProfileContracted(session.getUserProfile()) && (
          // session.getUserConfigs().contracted &&
          // session.getUserConfigs().contracted.type === util.TYPE_CONTRACTED_CONTRACTED &&
          <NavItem className="px-3">
            <Link
              style={{ color: "#fff" }}
              to={{
                pathname: "/contracteds/details",
                query: {
                  data: {
                    id: session.getUserConfigs().subSubContractedId
                      ? session.getUserConfigs().subSubContractedId
                      : session.getUserConfigs().subContractedId
                        ? session.getUserConfigs().subContractedId
                        : session.getUserConfigs().contractedId
                          ? session.getUserConfigs().contractedId
                          : "",
                    name: session.getUserConfigs().name,
                    fromMenu: true,
                  },
                  edit: true,
                },
              }}
            >
              {util.translate("companyData")}
            </Link>
          </NavItem>
        )}
        {/* {profiles.isProfileContracted(session.getUserProfile()) &&
          session.getUserConfigs().contracted &&
          session.getUserConfigs().contracted.type === util.TYPE_CONTRACTED_SUBCONTRACTED &&
          <NavItem className="px-3">
            <Link style={{ color: '#fff' }} to={{ pathname: '/subcontratadas/cadastro', query: { id: session.getUserConfigs().subContractedId, edit: true } }}>{util.translate("companyData")}</Link>
          </NavItem>
        } */}
        {showSubContracted && (
          <NavItem className="px-3">
            <Link style={{ color: "#fff" }} to="/contracteds/relatorio">
              {util.translate("subcontractors")}
            </Link>
          </NavItem>
        )}
        <NavItem className="px-3">
          <a
            className="nav-link"
            href={`${process.env.REACT_APP_V4_URI}/login`}
            target="_blank"
            rel="noopener noreferrer">
            Módulos
          </a>
        </NavItem>
      </>
    );
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <HeaderLogo />
        {/* <AppSidebarToggler className="d-md-down-none" display="lg" /> */}
        {/* Controle por perfil, para a exibição do menu superior */}
        {(profiles.isProfileContracted(session.getUserProfile()) ||
          profiles.isProfileSubContracted(session.getUserProfile())) && (
            <Nav className="d-md-down-none" navbar style={{ marginLeft: "50px" }}>
              {this.getMenu()}
            </Nav>
          )}
        <Nav className="ml-auto" navbar style={{ paddingRight: "20px" }}>
          <DefaultHeaderDropdown accnt />
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
